// GENERATE DEFAULT SKIN STYLES
@require "../../common/css/mixins";
@require "../../common/css/media-queries";
generate-legacy-skin($color-mgp);

// OVERRIDES: remove Etica Slab
.title,
.text-body > h2 {
	font-weight: 800;
}

.mgp2014 body {
	background: $color-white url(img/backdrop-2022.png) repeat-x 0 47px;
}

@media $mq-medium {
	.mgp2014 .group.widget:not(.menu-bar) .menu-main .menu__title a {
		background: url(img/mgp.svg) 0/164px 50px no-repeat;
		width: 164px;
		text-indent: -300%;
		height: 58px;
		margin-top: -14px;
		padding-bottom: 13px;
	}

	.mgp2014 .container-widget.menu-bar .menu .menu__title a {
		background-image: url(https://static.nrk.no/core-icons/major/11/nrk-logo-mgp.svg);
		background-repeat: no-repeat;
		background-position: 12px center;
		background-size: 1.5rem;
		padding-left: 48px;
	}
}
